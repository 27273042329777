import {
  GENDER_ENUM,
  METHOD_ENUM,
  REQUEST_ENUM,
} from '../../data/user/constant';
import { i18n } from '../../lib/lang';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (id?: string) => `user/psycho/info/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

interface VIDEO_INTER {
  id: string;
  name: string;
  url: string;
}

interface CERTIFICATE_INTER {
  id: string;
  name: string;
  url: string;
  mimetype: string;
}

interface DIPLOMA {
  id: string;
  name: string;
  url: string;
  mimetype: string;
}

interface PHOTO_INTER {
  id: string;
  name: string;
  url: string;
  mimetype: string;
}

export interface CATEGORY_RAW_INTER {
  id: string;
  category: CATEGORY_ENUM;
  createDate: string;
  description: string;
  updateDate: string;
  video: VIDEO_INTER;
  certificate: CERTIFICATE_INTER[];
  diploma: DIPLOMA[];
  price: number;
  duration: string | number;
}

export interface DATA_RAW_INTER {
  name: string;
  phone: string;
  about: string;
  duration: string | number;
  age: number;
  gender: GENDER_ENUM;
  category: CATEGORY_RAW_INTER[];
  certificate: CERTIFICATE_INTER[];
  createDate: string;
  description: string;
  diploma: DIPLOMA[];
  id: string;
  updateDate: string;
  lgbtFrendly: boolean;
  methodCustom: string;
  methodList: METHOD_ENUM[];
  needAge: number | null;
  needGender: number | null;
  photo: PHOTO_INTER;
  requestCustom: string;
  requestList: REQUEST_ENUM[];
  timezone: number;
  video: VIDEO_INTER;
  aboutMe: string;
  aboutMyPath: string;
  aboutMyService: string;
}

export interface CATEGORY_INTER extends Omit<CATEGORY_RAW_INTER, 'category'> {
  category: string;
}

export interface DATA_INTER
  extends Omit<
    DATA_RAW_INTER,
    'methodList' | 'category' | 'requestList' | 'gender' | 'timezone' | 'age'
  > {
  methodList: string[];
  category: CATEGORY_INTER[];
  requestList: string[];
  gender: string;
  timezone: string;
  age: string;
}

export const GENDER_VIEW = {
  [GENDER_ENUM.MALE]: i18n.t('USER.PSYCHOLOGIST.SELECT.MALE'),
  [GENDER_ENUM.FEMALE]: i18n.t('USER.PSYCHOLOGIST.SELECT.FEMALE'),
};

export const CATEGORY_VIEW = {
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.GROUP_PSYCHOTHERAPY',
  ),
  [CATEGORY_ENUM.CHILD_COUNSELING]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.CHILD_COUNSELING',
  ),
  [CATEGORY_ENUM.INDIVIDUAL_CONSULTATION]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.INDIVIDUAL_CONSULTATION',
  ),
  [CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.CONSULTATION_OF_A_PSYCHIATRIST',
  ),
  [CATEGORY_ENUM.COUPLES_COUNSELIN]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.COUPLES_COUNSELIN',
  ),
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY_TEENAGER]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.GROUP_PSYCHOTHERAPY_TEENAGER',
  ),
};
