import React from 'react';

import styled, { css } from 'styled-components';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import { THEME_ENUM } from '../../data/theme/constant';

import settingIcon from '../../asset/svg/menu-app/setting.svg';
import settingActiveIcon from '../../asset/svg/menu-app/setting-active.svg';

import { SETTINGS_PAGE_PATH } from '../../page/settings';
import { COLOR_ENUM } from '../../theme/color';
// import { ThemeChangeContainer } from '../theme-change';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { USER_ROLE } from '../../data/auth/constant';
import { CardDesktop } from './frame/card-desktop';
import { getMenuItems } from './constant';

export const Component: React.FC<{
  isActivePath: Function;
  theme: THEME_ENUM;
  pathname: any;
  role: USER_ROLE;
}> = ({ isActivePath, role }) => {
  const menuItems = getMenuItems(role);

  return (
    <>
      <Container>
        {/* <MenuElem title="COMMON.MENU.TITLE">
          <BurgerMenuContainer />
        </MenuElem> */}

        <ContentDesktop spacing={0}>
          <IconContainer>
            <LogoSvg
              src="./assets/svg/header/syayvoLogo.svg"
              alt="syaivo Logo"
            />
          </IconContainer>
          {/* <PageContainer spacing={0}>
      <ThemeContainer>
        <ThemeChangeContainer />
      </ThemeContainer>
    </PageContainer> */}
          <SettingsContainer>
            {menuItems
              .filter((item) => item.isVisible)
              .map(({ title, icon, iconActive, path }) => (
                <CardDesktop
                  key={path}
                  href={path}
                  title={title}
                  icon={icon}
                  iconActive={iconActive}
                  isActive={isActivePath(path)}
                />
              ))}
            <MenuWrapper>
              <CardDesktop
                href={SETTINGS_PAGE_PATH}
                title="NAVIGATION.TAB_APP.SETTINGS"
                icon={settingIcon}
                iconActive={settingActiveIcon}
                isActive={isActivePath(SETTINGS_PAGE_PATH)}
              />
            </MenuWrapper>
          </SettingsContainer>
        </ContentDesktop>
      </Container>
    </>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  position: relative;
  transform: translateY(calc(-50% - 100px));
`;

const LogoSvg = styled.img`
  width: 129px;
  height: 46px;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${Spacing(14)};
`;

const ContentDesktop = styled(GridElem)`
  display: none;
  width: 270px;
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  grid-template-rows: min-content auto min-content;
  height: calc(100vh - 24px); // minus margin
  position: fixed;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;

  @media screen and (max-width: 1110px) {
    width: 220px;
  }

  @media screen and (min-width: 900px) {
    display: block;
  }
`;

const SettingsContainer = styled.div`
  padding: ${Spacing(12)} ${Spacing(7)} ${Spacing(6)};
  height: 100%;
  flex-direction: column;
  display: flex;
  gap: ${Spacing(1)};
`;

// const ThemeContainer = styled.div`
//   padding-left: ${Spacing(3)};
// `;

// const PageContainer = styled(GridElem)`
//   padding: 54px ${Spacing(7)} ${Spacing(6)};
//   height: 100%;
//   align-content: space-between;
// `;

const Container = styled.div`
  grid-row: 1/3;
  margin: 12px;

  @media screen and (max-width: 1160px) {
    grid-row: unset;
  }
`;
