import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PSYCHO_FILTER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: `/user/request`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export enum FORM_VALUE_ENUM {
  REQUEST_LIST = 'requestList',
  METHOD_LIST = 'methodList',
  PSYCHO_CATEGORY = 'psychoCategory',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.REQUEST_LIST]: string;
  [FORM_VALUE_ENUM.METHOD_LIST]: string;
  [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: string;
}

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];
