import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const updateAction = ({ payload }: any) => {
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL,
    data: payload,
  });
};

export const getDateAction = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};
