import React from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { FormElem } from '../../common/form';
import { ResendComponent } from './frame/resend.component';
import { LayoutSectionElem } from '../../common/layout-section';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  email: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  email,
}) => {
  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <GridStyled size="mod">
          <TextElemStyled
            size="semiSmall"
            color="textPrimary"
            type="regular"
            tid="AUTH.RECOVERY_CONFIRM.DESCRIPTION"
            tvalue={{ email }}
          />
          {/* AUTH.RECOVERY_CONFIRM.DESCRIPTION_PREFIX */}

          <GridElem size="input">
            <FieldTextElem
              name={FORM_VALUE_ENUM.CODE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="AUTH.RECOVERY_CONFIRM.CODE"
              value={getFieldValue(FORM_VALUE_ENUM.CODE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.CODE)}
              error={isFieldError(FORM_VALUE_ENUM.CODE)}
            />
            <ResendComponent email={email} />
          </GridElem>
          <ButtonElem
            disabled={isSubmitDisabled()}
            type="submit"
            tid="AUTH.RECOVERY_CONFIRM.BUTTON"
          />
        </GridStyled>
      </Form>
    </>
  );
};

const TextElemStyled = styled(TextElem)`
  text-align: center;
  line-height: 1.7em;
`;

const GridStyled = styled(GridElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${Spacing(7)};
  gap: 20px;
  box-shadow: 0px 0px 50px 0px #24231e0d;
  max-width: 366px;
  justify-items: center;
`;

const Form = styled(FormElem)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(10)};
  max-width: 628px;
  align-items: center;
`;
