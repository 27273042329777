import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ACTION_ERROR_INTER } from './constant';
import { Component } from './component';
import { action, cancelAction } from './action';
import { USER_ROLE } from '../../data/auth/constant';
import { CONSULTATION_USER_MODULE_NAME } from '../consultation-user-item';

export const Container: React.FC<{
  paymentId: string;
  price: number;
  duration: number;
  isPaid: boolean;
  isCancel: boolean;
  role: USER_ROLE;
}> = ({ paymentId, price, duration, isPaid, isCancel, role }) => {
  const queryClient = useQueryClient();

  const getLink = useMutation(() => action(paymentId), {
    onSuccess: (data) => {
      const url = data?.data;
      if (url) {
        window.open(url, '_blank');
      }
    },
  });

  const cancelOrder = useMutation(() => cancelAction(paymentId), {
    onSuccess: () => {
      queryClient.invalidateQueries(CONSULTATION_USER_MODULE_NAME);
    },
  });

  const handlePay = () => getLink.mutate();
  const handleCancel = () => cancelOrder.mutate();

  const isLoading = () => {
    if (getLink.isLoading) {
      return true;
    }

    if (cancelOrder.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (getLink.isError && !getLink.isLoading && getErrorMessage()) {
      return true;
    }
    if (cancelOrder.isError && !cancelOrder.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = getLink.error as ACTION_ERROR_INTER;
    const errorCancel: ACTION_ERROR_INTER =
      cancelOrder.error as ACTION_ERROR_INTER;

    if (errorCancel) {
      return errorCancel.message;
    }

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      role={role}
      isSuccess={cancelOrder.isSuccess}
      handleCancel={handleCancel}
      handlePay={handlePay}
      isPaid={isPaid}
      isCancel={isCancel}
      price={price}
      duration={duration}
      isLoading={isLoading()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
