import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import {
  FORM_VALUE_ENUM,
  LGBT_OPTION_LIST,
  METHOD_OPTION_LIST,
  REQUEST_OPTION_LIST,
} from './constant';

import { TextElem } from '../../common/text';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { SelectElem } from '../../common/select';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { multiValueContainer } from '../../common/select/frame/multi-value';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue?: Function;
  setValue?: Function;
  onChangeSelect: (name: string, values: any) => void;
  isLoadingAction: boolean;
  isSuccessAction: boolean;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setValue = () => {},
  setFieldValue,
  isLoadingAction,
  onChangeSelect,
  isSuccessAction,
}) => {
  return (
    <div key="specialization">
      {isLoadingAction && <LoaderElem key="success-alert" />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccessAction && (
        <AlertActionElem type="success" tid="CATEGORY.DATA.SUCCESS" />
      )}

      <FormElem gap={7} onSubmit={formik.handleSubmit}>
        {isLoading && <SkeletonFormElem />}

        {isSuccess && (
          <Container>
            <FieldTextAreaElem
              isDot
              name={FORM_VALUE_ENUM.ABOUT_MY_PATH}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.ABOUT_MY_PATH)}
              title="USER.PSYCHOLOGIST.ABOUT_MY_PATH"
              placeholder="USER.PSYCHOLOGIST.ABOUT_MY_PATH_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_MY_PATH)}
              error={isFieldError(FORM_VALUE_ENUM.ABOUT_MY_PATH)}
            />

            <FieldTextAreaElem
              isDot
              name={FORM_VALUE_ENUM.ABOUT_MY_SERVICE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.ABOUT_MY_SERVICE)}
              title="USER.PSYCHOLOGIST.ABOUT_MY_SERVICE"
              placeholder="USER.PSYCHOLOGIST.ABOUT_MY_SERVICE_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_MY_SERVICE)}
              error={isFieldError(FORM_VALUE_ENUM.ABOUT_MY_SERVICE)}
            />

            <SelectElem
              placeholder="USER.PSYCHOLOGIST.REQUEST_LIST_PLACEHOLDER"
              isMulti
              name={FORM_VALUE_ENUM.REQUEST_LIST}
              options={REQUEST_OPTION_LIST}
              title="USER.PSYCHOLOGIST.REQUEST_LIST"
              errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.REQUEST_LIST)}
              onChange={onChangeSelect}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              customComponents={{ MultiValueContainer: multiValueContainer }}
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.REQUEST_CUSTOM}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
              placeholder="USER.PSYCHOLOGIST.REQUEST_CUSTOM_PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
              error={isFieldError(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
            />

            <SelectElem
              placeholder="USER.PSYCHOLOGIST.METHOD_LIST_PLACEHOLDER"
              isMulti
              name={FORM_VALUE_ENUM.METHOD_LIST}
              closeMenuOnSelect={false}
              options={METHOD_OPTION_LIST}
              title="USER.PSYCHOLOGIST.METHOD_LIST"
              errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
              error={isFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
              value={getFieldValue(FORM_VALUE_ENUM.METHOD_LIST)}
              onChange={onChangeSelect}
              hideSelectedOptions={false}
              customComponents={{ MultiValueContainer: multiValueContainer }}
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.METHOD_CUSTOM}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={getFieldValue(FORM_VALUE_ENUM.METHOD_CUSTOM)}
              placeholder="USER.PSYCHOLOGIST.METHOD_CUSTOM"
              errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_CUSTOM)}
              error={isFieldError(FORM_VALUE_ENUM.METHOD_CUSTOM)}
            />

            <SelectElem
              placeholder="USER.PSYCHOLOGIST.LGBT_FRENDLY_PLACEHOLDER"
              name={FORM_VALUE_ENUM.LGBT_FRENDLY}
              onChange={setFieldValue}
              options={LGBT_OPTION_LIST}
              title="USER.PSYCHOLOGIST.LGBT_FRENDLY"
              errorMessage={getFieldError(FORM_VALUE_ENUM.LGBT_FRENDLY)}
              error={isFieldError(FORM_VALUE_ENUM.LGBT_FRENDLY)}
              value={setValue(LGBT_OPTION_LIST, FORM_VALUE_ENUM.LGBT_FRENDLY)}
            />

            <FieldTextElem
              isDot
              name={FORM_VALUE_ENUM.URL}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="USER.PSYCHOLOGIST.URL"
              placeholder="USER.PSYCHOLOGIST.URL_PLACEHOLDER"
              value={getFieldValue(FORM_VALUE_ENUM.URL)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.URL)}
              error={isFieldError(FORM_VALUE_ENUM.URL)}
            />

            <TitleContainer>
              <Dot />
              <TextElem
                tid="USER.PSYCHOLOGIST.REQUIRED"
                type="light"
                size="input"
                color="textSecondary"
              />
            </TitleContainer>
            <ButtonElem
              disabled={isSubmitDisabled()}
              type="submit"
              tid="CATEGORY.DATA.BUTTON"
            />
          </Container>
        )}
      </FormElem>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;
