import {
  PAYMENT_ITEM_LIST_DATA_RAW_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
} from '../../data/payment/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PAYMENT_LINK_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (paymentId: string) => `/payment/link/liqpay/${paymentId}`,
  },
  PATCH: {
    TYPE: 'PATCH',
    URL: (paymentId: string) => `/consultation/update/${paymentId}`,
  },
};

export interface ACTION_RESPONSE_INTER
  extends PAYMENT_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends PAYMENT_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export const STEPPER_LIST = [
  { topic: i18n.t('PAYMENT.LIST.STATUS_NOT_SEEN') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_BUTTON_NOT_PRESSED') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_BUTTON_PRESSED') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_SENT') },
  { topic: i18n.t('PAYMENT.LIST.STATUS_PAID') },
];
