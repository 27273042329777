interface DateOption {
  value: string;
  label: string;
}

const monthGenitiveMap: { [key: string]: string } = {
  січень: 'січня',
  лютий: 'лютого',
  березень: 'березня',
  квітень: 'квітня',
  травень: 'травня',
  червень: 'червня',
  липень: 'липня',
  серпень: 'серпня',
  вересень: 'вересня',
  жовтень: 'жовтня',
  листопад: 'листопада',
  грудень: 'грудня',
};

export function generateDateList(userTimezoneOffset: number): DateOption[] {
  const today = new Date();
  const dateList: DateOption[] = [];
  const monthFormatter = new Intl.DateTimeFormat('uk-UA', { month: 'long' });

  function applyTimezoneOffset(date: Date, offset: number): Date {
    const utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
    return new Date(utcTime + offset * 3600000);
  }

  const threeMonthsLater = new Date(today);
  threeMonthsLater.setMonth(today.getMonth() + 3);

  for (
    let currentDate = new Date(today);
    currentDate <= threeMonthsLater;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const adjustedDate = applyTimezoneOffset(
      new Date(currentDate),
      userTimezoneOffset,
    );

    const utcDateString = new Date(
      Date.UTC(
        adjustedDate.getUTCFullYear(),
        adjustedDate.getUTCMonth(),
        adjustedDate.getUTCDate(),
      ),
    )
      .toISOString()
      .split('T')[0]; // Формат YYYY-MM-DD

    const month = monthFormatter.format(adjustedDate);
    const monthGenitive = monthGenitiveMap[month] || month;

    const day = String(adjustedDate.getDate()).padStart(2, '0');
    const formattedLabel = `${day} ${monthGenitive}`;

    dateList.push({
      value: utcDateString,
      label: formattedLabel,
    });
  }

  return dateList;
}
