import { ROLE_ENUM } from '../../common/button/constant';
import { CATEGORY_ENUM } from '../../epic/profile-psycho-create-category/constant';
import { i18n } from '../../lib/lang';
import { FILE_INTER } from '../file/constant';

export enum REQUEST_ENUM {
  DEPRESSION = 1,
  ANXIETY = 2,
  PHOBIAS = 3,
  UNCONTROLLED_ANGER = 4,
  GRIEF_LOSS = 5,
  TRAUMA_PTSD = 6,
  BURNOUT = 7,
  STRESS = 8,
  LOSS_OF_MEANING = 9,
  SELF_ESTIMATION = 10,
  UNCERTAINTY = 11,
  PSYCHOSOMATICS = 12,
  ADDICTION = 13,
  EATING_DISORDERS = 14,
  PANIC_ATTACKS = 15,
  INTIMACY_PROBLEMS = 16,
  PARENTING = 17,
  EMOTIONAL_INTELLIGENCE = 18,
  SPEECH_THERAPY_SESSIONS = 19,
  SEXUAL_EDUCATION = 20,
  NEURO_CORRECTION = 21,
  SUPERVISION = 22,
  MENTAL_DISORDERS = 23,
  ENURESIS = 24,
  ENCOPRESIS = 25,
  SLEEP_DISORDERS = 26,
  AUTOAGGRESSION = 27,
  ADHD = 28,
  SPEECH_DISORDERS = 29,
  AUTISM = 30,
}

export enum METHOD_ENUM {
  COGNITIVE_PROCESSING_THERAPY = 1,
  PSYCHOANALYSIS = 2,
  EXISTENTIAL_THERAPY = 3,
  MILITARY_PSYCHOLOGY = 4,
  ONCOPSYCHOLOGY = 5,
  NEUROPSYCHOLOGY = 6,
  TRANSACTIONAL_ANALYSIS = 7,
  PSYCHODRAMA = 8,
  ART_THERAPY = 9,
  GESTALT_THERAPY = 10,
  POSITIVE_PSYCHOTHERAPY = 11,
  TRAUMA_FOCUSED_PSYCHOTHERAPY = 12,
  EMDR_THERAPY = 13,
  CATATHYMIC_IMAGINATIVE_THERAPY = 14,
  SYSTEMIC_FAMILY_THERAPY = 15,
  NLP = 16,
  CLIENT_CENTERED_PSYCHOTHERAPY = 17,
  INTEGRATIVE_PSYCHOTHERAPY = 18,
  CAREER_GUIDANCE = 19,
  LOGOTHERAPY = 20,
  SOLUTION_FOCUSED_THERAPY = 21,
  PSYCHIATRY = 22,
  CRISIS_COUNSELING = 23,
  FAMILY_PSYCHOTHERAPY = 24,
}

export enum GENDER_ENUM {
  MALE = 1,
  FEMALE = 2,
}

export enum LGBT_FRIENDLY_ENUM {
  YES = 1,
  FEMALE = 2,
}

export const methodViewObj = {
  [METHOD_ENUM.COGNITIVE_PROCESSING_THERAPY]: 'Когнітивна терапія обробки',
  [METHOD_ENUM.PSYCHOANALYSIS]: 'Психоаналіз',
  [METHOD_ENUM.EXISTENTIAL_THERAPY]: 'Екзистенційна терапія',
  [METHOD_ENUM.MILITARY_PSYCHOLOGY]: 'Військова психологія',
  [METHOD_ENUM.ONCOPSYCHOLOGY]: 'Онкопсихологія',
  [METHOD_ENUM.NEUROPSYCHOLOGY]: 'Нейропсихологія',
  [METHOD_ENUM.TRANSACTIONAL_ANALYSIS]: 'Транзакційний аналіз',
  [METHOD_ENUM.PSYCHODRAMA]: 'Психодрама',
  [METHOD_ENUM.ART_THERAPY]: 'АРТ-терапія',
  [METHOD_ENUM.GESTALT_THERAPY]: 'Гештальт-терапія',
  [METHOD_ENUM.POSITIVE_PSYCHOTHERAPY]: 'Позитивна психотерапія',
  [METHOD_ENUM.TRAUMA_FOCUSED_PSYCHOTHERAPY]: 'Травмафокусована психотерапія',
  [METHOD_ENUM.EMDR_THERAPY]: 'EMDR терапія травми',
  [METHOD_ENUM.CATATHYMIC_IMAGINATIVE_THERAPY]:
    'Кататимно-імагінативна терапія',
  [METHOD_ENUM.SYSTEMIC_FAMILY_THERAPY]: 'Системно-сімейна терапія',
  [METHOD_ENUM.NLP]: 'НЛП',
  [METHOD_ENUM.CLIENT_CENTERED_PSYCHOTHERAPY]: 'Клієнт-центрована психотерапія',
  [METHOD_ENUM.INTEGRATIVE_PSYCHOTHERAPY]: 'Інтегративна психотерапія',
  [METHOD_ENUM.CAREER_GUIDANCE]: 'Профорієнтація',
  [METHOD_ENUM.LOGOTHERAPY]: 'Логотерапія',
  [METHOD_ENUM.SOLUTION_FOCUSED_THERAPY]: 'Терапія рішення',
  [METHOD_ENUM.PSYCHIATRY]: 'Психіатрія',
  [METHOD_ENUM.CRISIS_COUNSELING]: 'Кризове консультування',
  [METHOD_ENUM.FAMILY_PSYCHOTHERAPY]: 'Сімейна психотерапія',
};

export const requestViewObj = {
  [REQUEST_ENUM.DEPRESSION]: 'Депресія',
  [REQUEST_ENUM.ANXIETY]: 'Тривога',
  [REQUEST_ENUM.PHOBIAS]: 'Страх/Фобії',
  [REQUEST_ENUM.UNCONTROLLED_ANGER]: 'Неконтрольований гнів',
  [REQUEST_ENUM.GRIEF_LOSS]: 'Горе/Втрата',
  [REQUEST_ENUM.TRAUMA_PTSD]: 'Травма/ПТСР',
  [REQUEST_ENUM.BURNOUT]: 'Вигорання',
  [REQUEST_ENUM.STRESS]: 'Стрес',
  [REQUEST_ENUM.LOSS_OF_MEANING]: 'Втрата сенсів',
  [REQUEST_ENUM.SELF_ESTIMATION]: 'Самооцінка',
  [REQUEST_ENUM.UNCERTAINTY]: 'Невизначеність',
  [REQUEST_ENUM.PSYCHOSOMATICS]: 'Психосоматика',
  [REQUEST_ENUM.ADDICTION]: 'Залежність',
  [REQUEST_ENUM.EATING_DISORDERS]: 'Розлади харчування',
  [REQUEST_ENUM.PANIC_ATTACKS]: 'Панічні атаки',
  [REQUEST_ENUM.INTIMACY_PROBLEMS]: 'Інтимні проблеми',
  [REQUEST_ENUM.PARENTING]: 'Батьківство',
  [REQUEST_ENUM.EMOTIONAL_INTELLIGENCE]: 'Емоційний інтелект',
  [REQUEST_ENUM.SPEECH_THERAPY_SESSIONS]: 'Заняття з логопедом',
  [REQUEST_ENUM.SEXUAL_EDUCATION]: 'Сексуальне виховання',
  [REQUEST_ENUM.NEURO_CORRECTION]: 'Нейрокорекція',
  [REQUEST_ENUM.SUPERVISION]: 'Супервізія',
  [REQUEST_ENUM.MENTAL_DISORDERS]: 'Психічні розлади',
  [REQUEST_ENUM.ENURESIS]: 'Енурез',
  [REQUEST_ENUM.ENCOPRESIS]: 'Енкопрез',
  [REQUEST_ENUM.SLEEP_DISORDERS]: 'Розлади сну',
  [REQUEST_ENUM.AUTOAGGRESSION]: 'Аутоагресія',
  [REQUEST_ENUM.ADHD]: 'РДУГ',
  [REQUEST_ENUM.SPEECH_DISORDERS]: 'Розлади мовлення',
  [REQUEST_ENUM.AUTISM]: 'РАС',
};

export const genderViewObj = {
  [GENDER_ENUM.MALE]: {
    value: 1,
    label: 'Чоловіча',
  },
  [GENDER_ENUM.FEMALE]: {
    value: 2,
    label: 'Жіноча',
  },
};

export interface PSYCHOLOGIST_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  name: string;
  phone?: string | null;
  aboutMe?: string | null;
  aboutMyPath?: string | null;
  aboutMyService?: string | null;
  age?: number | null;
  gender?: GENDER_ENUM | null;
  timezone?: number;
  requestList: REQUEST_ENUM[];
  requestCustom?: string | null;
  lgbtFrendly?: boolean | null;
  methodList?: METHOD_ENUM[] | null;
  methodCustom?: string | null;
  needGender?: GENDER_ENUM | null;
  photo?: FILE_INTER;
  email: string;
  isFop?: boolean;
  accountNumber?: string;
  bankName?: string;
  role: USER_ROLE;
  password: string;
  url?: string;
}

export interface PSYCHOLOGIST_ITEM_DATA_INTER
  extends Omit<
    PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
    'methodList' | 'requestList' | 'gender' | 'lgbtFrendly'
  > {
  methodList?: OPTION_INTER[] | null;
  requestList?: OPTION_INTER[] | null;
  gender?: number | null;
  lgbtFrendly?: string | null;
}

export interface USER_ITEM_DATA_RAW_INTER {
  id: string;
  createDate: string;
  name: string;
  phone?: string | null;
  about?: string | null;
  age?: string | null;
  gender?: GENDER_ENUM | null;
  timezone?: number;
  requestList: REQUEST_ENUM[];
  requestCustom?: string | null;
  lgbtFrendly?: boolean | null;
  methodList?: METHOD_ENUM[] | null;
  methodCustom?: string | null;
  needGender?: GENDER_ENUM | null;
  needAge?: number | null;
  photo?: {
    url: string;
    id: string;
    name: string;
  };
  email: string;
  role: USER_ROLE;
  password: string;
  psychoCategory?: CATEGORY_ENUM;
  numberTimezone?: number; // по идее надо в ITEM_DATA_INTER
}

export interface USER_ITEM_LIST_DATA_RAW_INTER {
  list: USER_ITEM_DATA_RAW_INTER[];
}

export interface OPTION_INTER {
  value?: number | any | string;
  label?: string;
}

export interface USER_ITEM_DATA_INTER
  extends Omit<
    USER_ITEM_DATA_RAW_INTER,
    'methodList' | 'requestList' | 'gender' | 'needGender' | 'lgbtFrendly'
  > {
  methodList?: OPTION_INTER[] | null;
  requestList?: OPTION_INTER[] | null;
  gender?: OPTION_INTER;
  needGender?: number | null;
  lgbtFrendly?: string | null;
  psychoCategoryView?: OPTION_INTER;
}

export interface USER_ITEM_LIST_DATA_INTER {
  list: USER_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: '/user/data',
  },
};

export enum USER_ROLE {
  PSYCHOLOGIST = 'PSYCHOLOGIST',
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export type USER_TYPE = `${USER_ROLE}`;

export const CHIP_USER_VIEW = {
  [USER_ROLE.ADMIN]: 'rgba(32, 113, 187, 0.1)',
  [USER_ROLE.PSYCHOLOGIST]: 'rgba(49, 173, 76, 0.1)',
  [USER_ROLE.USER]: 'rgba(146, 78, 39, 0.1)',
};

export const CHIP_TEXT_USER_VIEW = {
  [USER_ROLE.ADMIN]: '#2071BB',
  [USER_ROLE.PSYCHOLOGIST]: '#31AD4C',
  [USER_ROLE.USER]: '#924E27',
};

export const USER_ROLE_OPTION_LIST = [
  {
    value: USER_ROLE.PSYCHOLOGIST,
    label: `${i18n.t('USER.ROLE.PSYCHOLOGIST')}`,
  },

  {
    value: USER_ROLE.USER,
    label: `${i18n.t('USER.ROLE.USER')}`,
  },

  {
    value: USER_ROLE.ADMIN,
    label: `${i18n.t('USER.ROLE.ADMIN')}`,
  },
];
