import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { getAction } from './action';

export const Container: React.FC = () => {
  const [isCreateForm, setIsCreateForm] = useState(false);

  const {
    data: preFetchData,
    isSuccess: preFetchSuccess,
    isLoading: preFetchIsLoading,
    isError: preFetchIsError,
  } = useQuery(MODULE_NAME, getAction, {
    refetchOnWindowFocus: false,
    onSettled: () => {
      setIsCreateForm(false);
    },
  });

  const resetForm = () => {
    setIsCreateForm(false);
  };

  const action = useMutation(getAction, {});

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      setIsCreateForm={setIsCreateForm}
      isCreateForm={isCreateForm}
      resetForm={resetForm}
      list={preFetchData}
      preFetchIsLoading={preFetchIsLoading}
      isSuccess={preFetchSuccess}
      isError={preFetchIsError}
      errorMessage={getErrorMessage()}
    />
  );
};
