import { CATEGORY_ENUM } from '../../epic/profile-psycho-create-category/constant';
import { PAYMENT_ITEM_DATA_RAW_INTER } from '../payment/constant';
import {
  PSYCHOLOGIST_ITEM_DATA_INTER,
  PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
  USER_ITEM_DATA_INTER,
  USER_ITEM_DATA_RAW_INTER,
} from '../user/constant';

export enum CONSULTATION_STATUS {
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  CANCEL = 'Cancel',
}

export interface CONSULTATION_RAW_INTER {
  id: string;
  createDate: string;
  updateDate: string;
  price: number;
  status: CONSULTATION_STATUS;
  date: string;
  comment: string;
  isCompleteClient: boolean;
  isCompletePsycho: boolean;
  category: CATEGORY_ENUM;
  psychologist: PSYCHOLOGIST_ITEM_DATA_RAW_INTER;
  user: USER_ITEM_DATA_RAW_INTER;
  duration: number;
  payment: PAYMENT_ITEM_DATA_RAW_INTER;
}

export interface CONSULTATION_DATA_INTER
  extends Omit<CONSULTATION_RAW_INTER, 'psychologist'> {
  psychologist: PSYCHOLOGIST_ITEM_DATA_INTER;
  timeView: string;
  dayView: string;
  paymentTimeView: string;
  paymentDayView: string;
  paymentView: string;
}
