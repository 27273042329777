import React from 'react';

import styled from 'styled-components';
import { PAYMENT_STATUS } from '../../data/payment/constant';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { USER_ROLE } from '../../data/auth/constant';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';

export const Component: React.FC<{
  price: number;
  duration: number;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isPaid: boolean;
  isCancel: boolean;
  handlePay: Function;
  handleCancel: Function;
  role: USER_ROLE;
  isSuccess: boolean;
}> = ({
  isLoading,
  errorMessage,
  isPaid,
  isCancel,
  duration,
  price,
  handlePay,
  handleCancel,
  role,
  isSuccess,
  isError,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem
          type="success"
          tid="CONSULTATION.USER.CANCEL_SUCCESS"
        />
      )}

      <Block>
        <InfoContainer>
          <Title
            tid="CONSULTATION.PRICE_DURATION"
            color="textSecondary"
            size="input"
          />
          <TextContainer>
            <TextElem
              oneLine
              tid="USER.PSYCHO.HEADER.PRICE"
              tvalue={{ value: price }}
              type="bold"
              size="large"
            />
            <TextElem
              oneLine
              size="semiSmall"
              type="medium"
              color="textPrimary"
              tid="USER.PSYCHO.HEADER.TIME"
              tvalue={{ value: duration }}
            />
          </TextContainer>
        </InfoContainer>
        {role !== USER_ROLE.USER && (
          <>
            {isPaid && !isCancel && (
              <ChipSuccess>
                <TextElem
                  tid="CONSULTATION.USER.PAID"
                  color="success"
                  size="semiSmall"
                />
              </ChipSuccess>
            )}
          </>
        )}
        {!isPaid && !isCancel && role !== USER_ROLE.USER && (
          <ChipError>
            <TextElem
              tid="CONSULTATION.USER.NO_PAID"
              color="noSuccess"
              size="semiSmall"
            />
          </ChipError>
        )}

        {isCancel && (
          <ChipError>
            <TextElem
              tid="CONSULTATION.USER.CANCELLATION"
              color="noSuccess"
              size="semiSmall"
            />
          </ChipError>
        )}

        {role === USER_ROLE.USER && !isPaid && !isCancel && (
          <ButtonContainer>
            <ButtonElemStyled
              tid="CONSULTATION.USER.PAY"
              type="submit"
              onClick={handlePay}
            />
            <ButtonElemStyled
              onClick={handleCancel}
              tid="CONSULTATION.USER.CANCEL"
              type="cancel"
            />
          </ButtonContainer>
        )}
      </Block>
    </>
  );
};

const Title = styled(TextElem)`
  @media screen and (max-width: 720px) {
    font-size: 12px;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: 17px ${Spacing(8)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]};

  span {
    font-size: 14px;
  }
`;

const Chip = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  padding: ${Spacing(3)} ${Spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing(2)};

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const ChipSuccess = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

const ChipError = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.NO_SUCCESS]};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100%;
    align-items: start;
    gap: ${Spacing(3)};
    padding: ${Spacing(5)};
  }
`;
