import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { ModalElem } from '../../../common/modal';
import { SelectElem } from '../../../common/select';
import { FORM_VALUE_ENUM } from '../constant';
import { Spacing } from '../../../theme';
import { ButtonElem } from '../../../common/button';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../../theme/size';

export const ModalTimeChange: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  formik: FormikValues;
  isSubmitDisabled: Function;
  dateOption: {
    label: string;
    value: string;
  }[];
  timeOption: {
    label: string;
    value: string;
  }[];
  handleSelectFocus: (value: boolean) => void;
  isFieldDisabled: Function;
  isTimeLoading: boolean;
  isChangeTimeDisabled: boolean;
}> = ({
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  dateOption,
  timeOption,
  handleSelectFocus,
  isTimeLoading,
  isFieldDisabled,
  isChangeTimeDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ButtonElemStyled
        type="button"
        disabled={isChangeTimeDisabled || isSubmitDisabled()}
        onClick={handleOpen}
        tid="CONSULTATION.USER.REASSIGN"
      />
      <ModalElemStyled onClose={handleClose} open={isOpen}>
        <Double>
          <SelectElem
            isDisabled={isFieldDisabled()}
            dynamic
            placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.DATE}
            onChange={setFieldValue}
            options={dateOption}
            title="ORDER.ITEM.REGISTRATION.DATE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
            error={isFieldError(FORM_VALUE_ENUM.DATE)}
            value={setValue(dateOption, FORM_VALUE_ENUM.DATE)}
          />

          <SelectElem
            isDisabled={isFieldDisabled()}
            dynamic
            isLoading={isTimeLoading}
            onFocus={() => handleSelectFocus(true)}
            onBlur={() => handleSelectFocus(false)}
            placeholder="ORDER.ITEM.REGISTRATION.TIME_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIME}
            onChange={setFieldValue}
            options={timeOption}
            title="ORDER.ITEM.REGISTRATION.TIME"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
            error={isFieldError(FORM_VALUE_ENUM.TIME)}
            value={setValue(timeOption, FORM_VALUE_ENUM.TIME)}
          />
        </Double>
        <ButtonElem
          disabled={isSubmitDisabled()}
          type="submit"
          onClick={() => {
            formik.handleSubmit();
            setIsOpen(false);
          }}
          tid="CONSULTATION.USER.REASSIGN"
        />
      </ModalElemStyled>
    </>
  );
};

const ModalElemStyled = styled(ModalElem)`
  ::part(content) {
    padding: ${Spacing(6)};
    overflow: visible !important;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: ${Spacing(5)} ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
  }
`;

const Double = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  margin-bottom: ${Spacing(6)};
`;
