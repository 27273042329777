import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  ACTION_RESPONSE_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
  USER_OPTION_LIST_QUERY_KEY,
} from './constant';
import { Component } from './component';
import { action } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { convert, convertData } from './convert';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import { CONSULTATION_ITEM_LIST_DATA_INTER } from '../consultation-list/constant';
import { converPsychoFilter } from '../../data/user/convert';
import { FILTER_OPTION } from '../consultation-list-admin/constant';
import { CONSULTATION_LIST_FILTER_MODULE_NAME } from './index';

const config = {
  [FORM_VALUE_ENUM.CONSULTATION_STATUS]: [],
  [FORM_VALUE_ENUM.PAYMENT_STATUS]: [],
};

export const Container: React.FC<{
  setOption: React.Dispatch<React.SetStateAction<FILTER_OPTION>>;
}> = ({ setOption }) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [initialValues, setInitialValues] = useState({
    [FORM_VALUE_ENUM.CONSULTATION_STATUS]: [],
    [FORM_VALUE_ENUM.PAYMENT_STATUS]: [],
    [FORM_VALUE_ENUM.DATE]: [],
  });
  const [date, setDate] = useState<[string, string] | []>([]);
  const [selectUserValue, setSelectUsertValue] = useState('');
  const [indentify, setIndentify] = useState<string | undefined>();
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const toggleFilterOpen = () => {
    setIsOpenFilter((prev) => !prev);
  };

  const preFetchUser = useQuery(USER_OPTION_LIST_QUERY_KEY, () =>
    action(selectUserValue),
  );

  const validate = (values: FormikValues) => validation(values, config);

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchUser.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectUserValue]);

  useEffect(() => {
    setOption((prevOption: FILTER_OPTION) => ({
      ...prevOption,
      consultationStatus: convert(formik.values, 'consultationStatus'),
      paymentStatus: convert(formik.values, 'paymentStatus'),
      indentify: indentify,
      startDate: date[0],
      endDate: date[1],
    }));
  }, [formik.values, date, indentify]);

  const handleSelectUserChange = (newValue: any, _actionMeta: any) => {
    setSelectUsertValue(newValue);
  };

  const getData = useMemo(() => {
    const data = preFetchUser.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      const converedData = convertData(data);

      return { ...converedData };
    }

    return undefined;
  }, [preFetchUser.data]);

  const isLogged = () => auth.logged;

  const isLoading = () => {
    if (!isLogged()) {
      return false;
    }
    if (preFetchUser.isLoading) {
      return true;
    }
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  // const saveFormikValuesToLocalStorage = (values: FormikValues) => {
  //   const keys = Object.keys(values);

  //   keys.forEach((key) => {
  //     const value = values[key];

  //     if (
  //       Array.isArray(value) &&
  //       value.every(
  //         (item) =>
  //           item.hasOwnProperty('label') && item.hasOwnProperty('value'),
  //       )
  //     ) {
  //       const valuesOnly = value.map((item) => item.value);
  //       localStorage.setItem(key, JSON.stringify(valuesOnly));
  //     } else if (typeof value === 'number') {
  //       localStorage.setItem(key, JSON.stringify(value));
  //     }
  //   });
  // };

  //   useEffect(() => {
  //     saveFormikValuesToLocalStorage(formik.values);
  //   }, [formik.values]);

  const userOptionList = getData?.list;

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, e?.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    if (value == FORM_VALUE_ENUM.USERS) {
      const optionList = options?.filter(
        (item: SELECT_OPTION_ITEM_DATA) => item?.label == formik.values[value],
      )[0];
      return optionList ? optionList : null;
    }
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item?.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      handleSelectUserChange={handleSelectUserChange}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoading={isLoading()}
      onChangeSelect={onChangeSelect}
      getFieldError={getFieldError}
      getFieldValue={getFieldValue}
      isFieldError={isFieldError}
      setDate={setDate}
      setIndentify={setIndentify}
      userList={userOptionList}
      date={date}
      isOpenFilter={isOpenFilter}
      toggleOpenFilter={toggleFilterOpen}
    />
  );
};
