import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ACTION_ERROR_INTER, SCHEDULE_PAYLOAD_INTER } from './constant';
import { Component } from './component';
import { updateAction } from './action';
import { DAY_ENUM } from '../schedule-list/constant';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { addOneHour } from '../../lib/util/addOneHour';

export const Container: React.FC<{
  time?: [string, string];
  day: string;
  id: DAY_ENUM;
  isWeekendDay?: boolean;
}> = ({ time, day, id, isWeekendDay }) => {
  const [isWeekend, setIsWeekend] = useState<boolean | undefined>(isWeekendDay);
  const [payload, setPayload] = useState<SCHEDULE_PAYLOAD_INTER>({
    startTime: time ? time[0] : undefined,
    endTime: time ? time[1] : undefined,
  });

  const onSuccess = () => {};

  const action = useMutation(updateAction, { onSuccess });

  const isSubmitDisabled = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const handleUpdate = ({
    startTime,
    endTime,
  }: {
    startTime?: string;
    endTime?: string;
  }) => {
    if (action.isLoading) return;

    if (payload?.startTime && payload.endTime) {
      return action.mutate({
        day: id,
        startTime: startTime,
        endTime: endTime,
      });
    }

    return;
  };

  const handleSetWeekend = () => {
    if (action.isLoading) return;

    setIsWeekend((prev) => {
      action.mutate({
        day: id,
        isWeekend: !prev,
      });

      return !prev;
    });
  };

  const updatePayload = (name: keyof SCHEDULE_PAYLOAD_INTER, value: string) => {
    setPayload((prev) => {
      const data = { ...prev, [name]: value };

      if (payload?.startTime && payload.endTime) {
        if (name === 'startTime' && value > payload.endTime) {
          handleUpdate({ endTime: addOneHour(value), [name]: value });

          setPayload(() => ({
            startTime: value,
            endTime: addOneHour(value),
          }));
        } else {
          handleUpdate({ ...prev, [name]: value });
        }
      }

      return data;
    });
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], name: string) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == name,
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      payload={payload}
      updatePayload={updatePayload}
      setValue={setValue}
      isWeekend={isWeekend}
      handleSetWeekend={handleSetWeekend}
      day={day}
      time={time}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};
