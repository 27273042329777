import React, { useState } from 'react';
import styled from 'styled-components';

import { TabSecondaryElem } from '../../common/tab-secondary';
import { HeaderSecondary } from '../../common/header-secondary';
import { ProfilePsychoUpdateContainer } from '../profile-psycho-update-data';
import { ProfilePsychoUpdateFileContainer } from '../profile-psycho-update-file';
import { Spacing } from '../../theme';
import { ProfilePsychoUpdateSpecializationContainer } from '../profile-psycho-update-specialization';
import { ProfilePsychoUpdateRegardContainer } from '../profile-psycho-update-regard';
import { ProfilePsychoListCategoryContainer } from '../profile-psycho-category-list';

export const Component: React.FC<{}> = ({}) => {
  const [active, setActive] = useState(1);

  const tabList = [
    { tid: 'USER.PSYCHOLOGIST.TAB.DATA', value: 1 },
    { tid: 'USER.PSYCHOLOGIST.TAB.SPECIALIZATION', value: 2 },
    { tid: 'USER.PSYCHOLOGIST.TAB.REWARD', value: 3 },
    { tid: 'USER.PSYCHOLOGIST.TAB.CATEGORY', value: 4 },
  ];

  return (
    <Wrapper>
      <HeaderSecondary
        titleTid="USER.PSYCHOLOGIST.TITLE.NAME"
        prefixTid="USER.PSYCHOLOGIST.TITLE.PREFIX"
        sufixTid="USER.PSYCHOLOGIST.TITLE.SUFIX"
        postfixTid="USER.PSYCHOLOGIST.TITLE.POSTFIX"
      />
      <TabSecondaryElem
        tabList={tabList}
        active={active}
        setActive={setActive}
      />
      {active === 1 && (
        <React.Fragment key="tab-1">
          <ContentContainer>
            <ProfilePsychoUpdateFileContainer />
            <ProfilePsychoUpdateContainer />
          </ContentContainer>
        </React.Fragment>
      )}
      {active === 2 && (
        <React.Fragment key="tab-2">
          <ProfilePsychoUpdateSpecializationContainer />
        </React.Fragment>
      )}
      {active === 3 && (
        <React.Fragment key="tab-3">
          <ProfilePsychoUpdateRegardContainer />
        </React.Fragment>
      )}

      {active === 4 && (
        <React.Fragment key="tab-4">
          <ProfilePsychoListCategoryContainer />
        </React.Fragment>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};

  @media screen and (max-width: 1160px) {
    max-width: unset;
    margin-top: ${Spacing(8)};
    margin-bottom: ${Spacing(25)};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;
