import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import psychoIcon from '../../asset/svg/menu-app/psycho-list.svg';

import { FORM_VALUE_ENUM } from './constant';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { CATEGORY_OPTION_LIST } from '../profile-psycho-create-category/constant';
import {
  METHOD_OPTION_LIST,
  REQUEST_OPTION_LIST,
} from '../profile-psycho-update-specialization/constant';
import { COLOR_ENUM } from '../../theme/color';
import arrowSvg from '../../asset/svg/button/arrow-down.svg';
import arrowExpandedSvg from '../../asset/svg/button/arrow-down-expanded.svg';
import { multiValueContainer } from '../../common/select/frame/multi-value';

export const Component: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  onChangeSelect: (name: string, values: any) => void;
  getFieldValue: Function;
  isLoading?: boolean;
  setFieldValue: Function;
  setValue: Function;
}> = ({
  isFieldError,
  getFieldError,
  onChangeSelect,
  getFieldValue,
  isLoading,
  setFieldValue,
  setValue,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <Container>
      <HeaderContainer>
        <img src={psychoIcon} alt="header logo" />
        <TextElem
          color="textPrimary"
          type="medium"
          size="medium"
          tid="Психологи"
        />
      </HeaderContainer>
      <SelectContainerDesktop>
        <SelectElem
          isClearable
          maxWidth="190px"
          minWidth="190px"
          backgroundColor="white"
          placeholder="PSYCHO_LIST.FILTER.CATEGORY"
          name={FORM_VALUE_ENUM.PSYCHO_CATEGORY}
          onChange={setFieldValue}
          options={CATEGORY_OPTION_LIST}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
          error={isFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
          value={setValue(
            CATEGORY_OPTION_LIST,
            FORM_VALUE_ENUM.PSYCHO_CATEGORY,
          )}
        />

        <SelectElem
          isClearable
          backgroundColor="white"
          maxWidth="190px"
          minWidth="190px"
          placeholder="PSYCHO_LIST.FILTER.METHOD"
          isMulti
          name={FORM_VALUE_ENUM.METHOD_LIST}
          options={METHOD_OPTION_LIST}
          errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
          error={isFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
          value={getFieldValue(FORM_VALUE_ENUM.METHOD_LIST)}
          onChange={onChangeSelect}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          customComponents={{ MultiValueContainer: multiValueContainer }}
          isDisabled={isLoading}
        />

        <SelectElem
          isClearable
          backgroundColor="white"
          maxWidth="190px"
          minWidth="190px"
          placeholder="PSYCHO_LIST.FILTER.REQUEST"
          isMulti
          name={FORM_VALUE_ENUM.REQUEST_LIST}
          options={REQUEST_OPTION_LIST}
          errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
          error={isFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
          value={getFieldValue(FORM_VALUE_ENUM.REQUEST_LIST)}
          onChange={onChangeSelect}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          customComponents={{ MultiValueContainer: multiValueContainer }}
          isDisabled={isLoading}
        />
      </SelectContainerDesktop>
      <SelectContainerMobile isExpanded={isExpanded}>
        <FlexContainer onClick={toggleExpand}>
          <TextElem
            type="medium"
            color="textPrimary"
            size="mobileDefault"
            tid="PSYCHO_LIST.FILTER.TITLE"
          />
          <ArrowImg
            className="arrow"
            isExpanded={isExpanded}
            src={isExpanded ? arrowExpandedSvg : arrowSvg}
            alt="arrow"
          />
        </FlexContainer>
        <SelectInputContainer isExpanded={isExpanded}>
          <SelectElem
            isClearable
            placeholder="PSYCHO_LIST.FILTER.CATEGORY"
            name={FORM_VALUE_ENUM.PSYCHO_CATEGORY}
            onChange={setFieldValue}
            options={CATEGORY_OPTION_LIST}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
            error={isFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
            value={setValue(
              CATEGORY_OPTION_LIST,
              FORM_VALUE_ENUM.PSYCHO_CATEGORY,
            )}
          />

          <SelectElem
            placeholder="PSYCHO_LIST.FILTER.METHOD"
            isMulti
            isClearable
            name={FORM_VALUE_ENUM.METHOD_LIST}
            options={METHOD_OPTION_LIST}
            errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
            error={isFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
            value={getFieldValue(FORM_VALUE_ENUM.METHOD_LIST)}
            onChange={onChangeSelect}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            isDisabled={isLoading}
          />

          <SelectElem
            placeholder="PSYCHO_LIST.FILTER.REQUEST"
            isMulti
            isClearable
            name={FORM_VALUE_ENUM.REQUEST_LIST}
            options={REQUEST_OPTION_LIST}
            errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
            error={isFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
            value={getFieldValue(FORM_VALUE_ENUM.REQUEST_LIST)}
            onChange={onChangeSelect}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            isDisabled={isLoading}
          />
        </SelectInputContainer>
      </SelectContainerMobile>
    </Container>
  );
};

const SelectInputContainer = styled.div<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArrowImg = styled.img<{ isExpanded: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: ${Spacing(2)};
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 1070px) {
    display: none;
  }

  @media screen and (max-width: 1160px) {
    display: flex;

    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LABEL]}px;
    }
  }
`;

const SelectContainerMobile = styled.div<{ isExpanded: boolean }>`
  display: none;
  gap: ${Spacing(3)};
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  padding: ${Spacing(4)};
  height: ${({ isExpanded }) => (isExpanded ? 'auto' : Spacing(12))};
  width: 100%;

  @media screen and (max-width: 1160px) {
    display: flex;
  }
`;

const SelectContainerDesktop = styled.div`
  display: flex;
  gap: ${Spacing(3)};

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: ${Spacing(10)};
  align-self: end;
  padding: 0px 24px;
  align-items: center;
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
  );

  @media screen and (max-width: 1160px) {
    flex-direction: column;
    gap: ${Spacing(6)};
  }
`;
