import React from 'react';
import styled from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';
import tickSquare from '../../asset/svg/button/tick-square.svg';
import { TimePickerRangeElem } from '../../common/time-picker-range';
import { SelectElem } from '../../common/select';
import { WEEKEND_PAYLOAD_INTER } from './constant';
import addSvg from '../../asset/svg/common/add.svg';
import { timeOption } from '../../data/time/constant';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setValue: Function;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleSuccess: () => void;
  isAddWeekend: boolean;
  handleAddForm: () => void;
  dateOption: { label: string; value: string }[];
  updatePayload: (name: keyof WEEKEND_PAYLOAD_INTER, value: string) => void;
}> = ({
  isLoading,
  setValue,
  isError,
  errorMessage,
  isSuccess,
  handleSuccess,
  isAddWeekend,
  handleAddForm,
  dateOption,
  updatePayload,
  isSubmitDisabled,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SCHEDULE.WEEKEND.SUCCESS" />
      )}
      <Card>
        <ButtonElemStyled
          padding="16px"
          iconRight={addSvg}
          iconSize="large"
          onClick={handleAddForm}
          disabled={isLoading || isAddWeekend}
          type="button"
          tid="SCHEDULE.WEEKEND.ADD_BUTTON"
        />
        {isAddWeekend && (
          <AddCardContainer>
            <SelectElem
              minHeight="48px"
              name="day"
              isDisabled={isLoading}
              dynamic
              placeholder="SCHEDULE.WEEKEND.DAY_LIST_PLACEHOLDER"
              onChange={(
                _name: string,
                value: { value: string; label: string },
              ) => {
                updatePayload('date', value.value);
              }}
              options={dateOption}
              value={setValue(dateOption, 'date')}
            />
            <FlexContainer>
              <SelectElem
                name="startTime"
                minHeight="48px"
                isDisabled={isLoading}
                dynamic
                onChange={(
                  _name: string,
                  value: { value: string; label: string },
                ) => {
                  updatePayload('startTime', value.value);
                }}
                options={timeOption}
                value={setValue(timeOption, 'startTime')}
              />
              <SelectElem
                name="endTime"
                minHeight="48px"
                isDisabled={isLoading}
                dynamic
                onChange={(
                  _name: string,
                  value: { value: string; label: string },
                ) => {
                  updatePayload('endTime', value.value);
                }}
                options={timeOption}
                value={setValue(timeOption, 'endTime')}
              />

              <WeekendButton
                disabled={isSubmitDisabled()}
                onClick={handleSuccess}
              >
                <ImgStyled src={tickSquare} alt="calendar" />
              </WeekendButton>
            </FlexContainer>
          </AddCardContainer>
        )}
      </Card>
    </>
  );
};

const FlexContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 48px;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 470px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  div {
    justify-content: center;
  }
`;

const ImgStyled = styled.img`
  width: ${Spacing(5)};
  height: ${Spacing(5)};
`;

const WeekendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
  padding: ${Spacing(1)} 14px;
  min-height: 48px;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :disabled {
    opacity: 0.7;
  }

  :hover:not(:disabled) {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const AddCardContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  grid-template-columns: 134px 1fr;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;
