import React, { useRef } from 'react';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { Spacing } from '../../theme';
import { PROPS_TYPE } from './constant';
import { TextElem } from '../text';
import { i18n } from '../../lib/lang';
import { VALUE_OPACITY_DATA, VALUE_OPACITY_ENUM } from '../../theme/value';
import arrowIcon from '../../asset/svg/common/selectArrow.svg';
import checkIcon from '../../asset/svg/common/selectCheck.svg';
import { ReactComponent as IndicatorIcon } from '../../asset/svg/common/selectIndicator.svg';
import { ReactComponent as IndicatorIconWhite } from '../../asset/svg/common/selectIndicator-white.svg';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close-white.svg';

export const Elem: React.FC<PROPS_TYPE> = ({
  placeholder,
  onChange,
  defaultValue,
  title,
  options,
  name,
  value,
  error,
  errorMessage,
  noOptionsMessage,
  dynamic,
  onInputChange,
  textOnly = false,
  noAlertContainer = false,
  customComponents = {},
  className,
  isDisabled,
  isMulti = false,
  isRtl = false,
  closeMenuOnSelect = true,
  hideSelectedOptions = false,
  ref,
  formatGroupLabel,
  maxWidth,
  minHeight,
  style,
  isDot,
  minWidth,
  backgroundColor,
  isClearable,
  onBlur,
  onFocus,
  isLoading,
  menuIsOpen,
  filterOption,
}) => {
  const refSelect = useRef<any>();

  const handleChange = (e: any) => {
    if (onChange && e) {
      onChange(name, e);
    }
  };

  const noOptionsMessageFn = (inputValue: string) => {
    return !inputValue ? 'Почніть вводити' : 'Нічого не знайдено';
  };

  const ClearIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <CloseIcon />
      </components.ClearIndicator>
    );
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    const isOpen = props.selectProps.menuIsOpen;
    return (
      <components.DropdownIndicator {...props}>
        {isOpen ? <IndicatorIconWhite /> : <IndicatorIcon />}
      </components.DropdownIndicator>
    );
  };

  return (
    <Container className={className} style={style}>
      {title && (
        <TitleContainer>
          <TextElem
            type="light"
            color="textSecondary"
            size="input"
            tid={title}
          />

          {isDot && <Dot />}
        </TitleContainer>
      )}
      <CustomSelect
        backgroundColor={backgroundColor}
        value={value || ''}
        classNamePrefix={'Select'}
        options={isLoading ? [] : options}
        placeholder={placeholder && i18n.t(placeholder)}
        onChange={handleChange}
        name={name}
        isSearchable={dynamic || false}
        onBlur={onBlur}
        onFocus={onFocus}
        noOptionsMessage={({ inputValue }: any) =>
          noOptionsMessage ? noOptionsMessage : noOptionsMessageFn(inputValue)
        }
        filterOption={filterOption}
        menuIsOpen={menuIsOpen}
        onInputChange={onInputChange}
        menuPlacement="auto"
        textOnly={textOnly}
        defaultValue={defaultValue || 'select'}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isRtl={isRtl}
        hideSelectedOptions={hideSelectedOptions}
        // controlShouldRenderValue={false}
        formatGroupLabel={formatGroupLabel}
        // autoFocus={true}
        ref={refSelect}
        components={{
          ...customComponents,
          DropdownIndicator,
          ClearIndicator,
        }}
        maxWidth={maxWidth}
        minWidth={minWidth}
        minHeight={minHeight}
        isClearable={isClearable}
        isLoading={isLoading}
      />

      {(error || errorMessage) && (
        <TextElem size="alert" color="error">
          {errorMessage}
        </TextElem>
      )}
    </Container>
  );
};

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  width: 100%;
`;

const CustomSelect = styled(Select)<{
  textOnly: boolean;
  isDisabled: true | undefined;
  maxWidth?: string;
  minHeight?: string;
  minWidth?: string;
  backgroundColor?: COLOR_TYPE;
}>`
  *:has(+ &) {
    transition: all 0.2s;
  }
  font-family: 'e-Ukraine' !important;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;

  max-width: ${({ maxWidth = '' }) => maxWidth};
  min-width: ${({ minWidth = '' }) => minWidth};

  .Select__multi-value__remove {
    display: none;
  }

  .Select__indicator:not(.Select__clear-indicator) {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }

  .Select__clear-indicator {
    display: ${({ isClearable }) => (isClearable ? '' : 'none')} !important;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    position: relative;
    right: 10px;
  }

  .Select__multi-value {
    background-color: transparent;
    max-width: 25%;

    /* .Select__control
      > .Select__indicators
      > .Select__indicator.Select__dropdown-indicator {
      top: 20px;
      position: relative;
    } */

    .Select__multi-value__label {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
      font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
      padding: unset !important;

      @media screen and (max-width: 1160px) {
        font-size: 13px !important;
      }
    }
  }

  .Select__multi-value:not(:nth-last-child(2)) ::after {
    background-color: transparent;

    content: ',';
  }

  .Select__value-container--is-multi {
    font-weight: 300 !important;
    display: flex !important;
    justify-content: start !important;
    max-width: calc(100% - 30px) !important;

    width: 10px !important;
    flex-wrap: nowrap !important;
    overflow: auto;
    text-overflow: ellipsis;
    align-items: start;
    span {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 2px;
    }

    ${({ maxWidth }) => {
      if (maxWidth) {
        return css`
          max-width: calc(${maxWidth} - 400px);
        `;
      }
    }}

    font-weight: ${SIZE_FONT_WEIGHT_DATA[
      SIZE_FONT_WEIGHT_ENUM.LIGHT
    ]} !important;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px !important;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;

    @media screen and (max-width: 1160px) {
      font-size: 13px !important;
    }
  }

  /* #react-select-5-input {
    display: none;
  } */

  & .Select__control {
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
    min-height: ${({ minHeight }) => (minHeight ? minHeight : '54px')};
    max-height: 54px;
    overflow: hidden !important;
    cursor: pointer;
    background-color: ${({ backgroundColor, theme }) =>
      backgroundColor
        ? backgroundColor
        : theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;

    box-sizing: border-box;

    padding: ${Spacing(0)} ${Spacing(4)} ${Spacing(0)} ${Spacing(4)};
    width: 100%;

    box-shadow: none;

    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

    .Select__single-value {
      font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
    }

    &:hover:not(:focus) {
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

      .Select__single-value {
        /* color: ${({ theme }) =>
          theme[COLOR_ENUM.TEXT_PRIMARY]} !important; */
      }
    }
  }

  & .Select__control--is-focused {
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};

    :hover:not(:focus) {
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
    }
  }

  & .Select__control--menu-is-open {
    *:has(+ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }

    & .Select__indicator.Select__dropdown-indicator {
      transform: rotate(180deg) translateY(50%);
    }
    & .Select__placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    }

    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px 0px 0px;
    border-bottom: 1px solid transparent;

    @media screen and (max-width: 1160px) {
      font-size: 13px !important;
    }
  }
  position: relative;

  & .Select__value-container {
    padding: 0;
    align-items: center;

    .Select__placeholder {
      @media screen and (max-width: 1160px) {
        font-size: 13px !important;
      }
    }
  }

  & .Select__input-container {
    margin: 0;
    padding: 0;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};
    color: ${({ theme }) => theme[COLOR_ENUM.SELECT_TEXT_ACTIVE]} !important;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

    @media screen and (max-width: 1160px) {
      font-size: 13px !important;
    }
  }
  & .Select__single-value {
    color: ${({ theme }) => theme[COLOR_ENUM.SELECT_TEXT_DEFAULT]};

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

    @media screen and (max-width: 1160px) {
      font-size: 13px !important;
    }
  }

  .Select__control--menu-is-open {
    * > div {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]} !important;
    }
  }
  & .Select__placeholder {
    margin: 0;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};

    @media screen and (width<=600px) {
      font-size: 13px !important;
    }
  }
  & .Select__indicator-separator {
    display: none;
  }
  & .Select__indicator.Select__dropdown-indicator {
    /* width: 17px; */
    color: #000;
    padding: 0;
    transition: all 0.2s;
    margin-left: ${Spacing(2)};
  }
  & .Select__menu {
    top: unset;

    border-radius: 0px 0px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
    margin: 0;

    background-color: ${({ backgroundColor, theme }) =>
      backgroundColor
        ? backgroundColor
        : theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;

    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
    box-shadow: none;
    z-index: 11;
  }

  & .Select__menu-list {
    padding: 0;
    overflow-x: hidden;

    border-radius: 0px 0px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px
      ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

    background-color: ${({ backgroundColor, theme }) =>
      backgroundColor
        ? backgroundColor
        : theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;

    & :last-child {
      border-color: transparent;
    }
  }
  & .Select__menu-notice {
    text-align: left;
  }
  & .Select__option {
    cursor: pointer;
    position: relative;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};

    width: 100%;

    :hover {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    }

    border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

    @media screen and (max-width: 1160px) {
      font-size: 13px !important;
    }

    padding: 15px ${Spacing(4)};
    display: block;
    align-items: center;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .Select__option:hover {
    background-color: ${({ backgroundColor, theme }) =>
      backgroundColor
        ? backgroundColor
        : theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
  }
  & .Select__option--is-selected {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    background-color: transparent;
    position: relative;
  }

  & .Select__menu-list--is-multi > .Select__option::after {
    content: '';
    width: 16px !important;
    height: 16px !important;
    display: block;
    position: absolute;
    right: 16px;
    top: 15px;
  }

  & .Select__menu-list--is-multi > .Select__option--is-selected::after {
    background: url(${arrowIcon});
    background-size: 12px 12px !important;
    right: 15px;
    background-repeat: no-repeat;
  }

  & .Select__option--is-focused {
    background-color: transparent;
  }

  ${({ textOnly }) => {
    if (textOnly) {
      return css`
        & .Select__control {
          background-color: transparent;
          padding-top: 0;
          padding-bottom: 0;
          height: 50px;
        }
        & .Select__menu {
          border-radius: ${SIZE_BORDER_RADIUS_DATA[
            SIZE_BORDER_RADIUS_ENUM.INPUT
          ]}px;

          padding-top: 0;
          right: 0;
          width: 100%;
          min-width: fit-content;
          background-color: ${({ theme }) =>
            theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
        }
        & .Select__menu-list {
          border-radius: ${SIZE_BORDER_RADIUS_DATA[
            SIZE_BORDER_RADIUS_ENUM.INPUT
          ]}px;

          background-color: ${({ theme }) =>
            theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
        }
      `;
    }
  }}
  ${({ isDisabled }) => {
    if (isDisabled) {
      return css`
        & .Select__control {
          opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
        }
      `;
    }
  }}
`;
