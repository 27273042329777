import React, { useState } from 'react';
import styled from 'styled-components';

import { USER_INTER } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';

export const Profile: React.FC<{ user?: USER_INTER }> = ({ user }) => {
  return (
    <NameContainer>
      <ImgContainer>
        <ImgStyled src={user?.photo.url} alt="photo" />
      </ImgContainer>
      <NameInfo>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          tid={user?.name ?? 'COMMON.NO_NAME'}
        />
        <TextElem
          type="regular"
          color="textSecondary"
          size="semiSmall"
          tid="USER.PSYCHO.PROFILE.NAME"
          tvalue={{
            age: user?.age && `${user?.age}`,
            gender: user?.gender && `• ${user?.gender}`,
            timezone: user?.timezone && `• ${user?.timezone}`,
            lgbtFrendly: user?.lgbtFrendly ? '• LGBT+' : '',
          }}
        />
        <TextElem />
      </NameInfo>
    </NameContainer>
  );
};

const NameInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  flex-direction: column;
`;

const ImgContainer = styled.div`
  min-width: 72px;
  min-height: 72px;
`;

const ImgStyled = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  object-fit: cover;
  width: 72px;
  height: 72px;
`;

const NameContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  gap: ${Spacing(4)};
  padding: ${Spacing(4)} ${Spacing(5)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;
