import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { WeekendItemContainer } from '../weekend-item';
import { TextElem } from '../../common/text';
import { WeekendInfoItem } from './frame/card';
import { WEEKEND_SCHEDULE_DATA } from './constant';

export const Component: React.FC<{
  isLoading?: boolean;
  data?: WEEKEND_SCHEDULE_DATA[];
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleDelete: Function;
}> = ({ isLoading, isError, errorMessage, isSuccess, data, handleDelete }) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SCHEDULE.WEEKEND.SUCCESS_DELETE" />
      )}

      <Container>
        <TitleContainer>
          <Title
            type="medium"
            size="main"
            color="textPrimary"
            tid="SCHEDULE.WEEKEND.TITLE"
          />
          <Description
            type="light"
            size="small"
            color="textSecondary"
            tid="SCHEDULE.WEEKEND.DESCRIPTION"
          />
        </TitleContainer>
        <WeekendContainer>
          <WeekendItemContainer />

          {Array.isArray(data) && (
            <>
              {data.map((e) => (
                <WeekendInfoItem
                  key={e.id}
                  startTime={e.time[0]}
                  endTime={e.time[1]}
                  day={e.day}
                  deleteAction={() => handleDelete(e.id)}
                />
              ))}
            </>
          )}
        </WeekendContainer>
      </Container>
    </>
  );
};

const Title = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const WeekendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  margin-bottom: ${Spacing(3)};

  @media screen and (max-width: 1160px) {
    margin-bottom: ${Spacing(2)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};

  @media screen and (max-width: 1160px) {
    margin-bottom: ${Spacing(50)};
  }
`;
