import React from 'react';
import { Component } from './component';
import { useLocation } from 'react-router';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC = () => {
  const router = useLocation();

  const isActivePath = (...path: string[]) =>
    path.some((el) => router.pathname.includes(el));

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const role = auth.user?.role;

  return <Component role={role} isActivePath={isActivePath} />;
};
