import { TIMEZONE_ENUM } from '../../data/user/timezone';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { WEEKEND_SCHEDULE_DATA, WEEKEND_SCHEDULE_RAW } from './constant';

export const convert = (
  schedule: WEEKEND_SCHEDULE_RAW,
  timezone: TIMEZONE_ENUM,
): WEEKEND_SCHEDULE_DATA[] => {
  return Object.keys(schedule).map((key) => {
    const { day } = convertDateToLocal(key, timezone);

    // const { day: startDay, time: startTime } = convertDateToLocal(
    //   schedule[key][0],
    //   timezone,
    // );

    // const { time: endTime } = convertDateToLocal(schedule[key][1], timezone);

    const time: [string, string] = [schedule[key][0], schedule[key][1]];

    const convert = {
      id: key,
      day,
      time: time,
    };

    return convert;
  });
};
