import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FILE_STATE_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
  validate,
} from './constant';
import { Component } from './component';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { FormikValues, useFormik } from 'formik';
import { action as fetch } from './action';
import { PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME } from '../profile-psycho-category-list';

export const Container: React.FC<{
  isOpen: boolean;
  onToggle: () => void;
  onSuccess?: Function;
}> = ({ isOpen, onToggle, onSuccess }) => {
  const queryClient = useQueryClient();
  const [diploma, setDiploma] = useState<FILE_STATE_INTER[]>([]);
  const [certificate, setCertificate] = useState<FILE_STATE_INTER[]>([]);
  const [video, setVideo] = useState<File | null>(null);

  const [addDiplomaCount, setAddDiplomaCount] = useState(1);
  const [addCertificateCount, setAddCertificateCount] = useState(1);

  const initialValues = {
    [FORM_VALUE_ENUM.CATEGORY]: '',
    [FORM_VALUE_ENUM.DESCRIPTION]: '',
    [FORM_VALUE_ENUM.PRICE]: '',
  };

  const action = useMutation(fetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME);

      onSuccess && onSuccess();
    },
  });

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append(
      FORM_VALUE_ENUM.CATEGORY,
      formik.values[FORM_VALUE_ENUM.CATEGORY],
    );

    formData.append(
      FORM_VALUE_ENUM.PRICE,
      formik.values[FORM_VALUE_ENUM.PRICE],
    );

    formData.append(
      FORM_VALUE_ENUM.DESCRIPTION,
      formik.values[FORM_VALUE_ENUM.DESCRIPTION],
    );
    diploma.forEach((file) => {
      formData.append(`diploma`, file.file);
    });
    certificate.forEach((file) => {
      formData.append(`certificate`, file.file);
    });

    if (video) {
      formData.append('video', video);
    }

    action.mutate({ payload: formData });
  };

  const setDiplomaState = (file: FILE_STATE_INTER) => {
    setDiploma((prevDiploma) => [...prevDiploma, file]);
  };

  const setCertificateState = (file: FILE_STATE_INTER) => {
    setCertificate((prevCertificate) => [...prevCertificate, file]);
  };

  const onDeleteDiploma = (id: string) => {
    setDiploma((prevDiploma) => prevDiploma.filter((file) => file.id !== id));
  };

  const onDeleteVideo = () => {
    setVideo(null);
  };

  const onDeleteCertificate = (id: string) => {
    setCertificate((prevCertificate) =>
      prevCertificate.filter((file) => file.id !== id),
    );
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {},
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isDisabledButton = diploma?.length === 0 || certificate?.length === 0;

  const isSubmitDisabled = () => {
    // if (isDisabledButton) {
    //   return true;
    // }

    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (
    options: { value: number; label: string }[],
    value: any,
  ) => {
    const optionList = options?.filter(
      (item: any) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      onDeleteVideo={onDeleteVideo}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled()}
      onToggle={onToggle}
      isOpen={isOpen}
      setVideo={setVideo}
      setDiplomaState={setDiplomaState}
      setAddDiplomaCount={setAddDiplomaCount}
      setCertificateState={setCertificateState}
      onDeleteDiploma={onDeleteDiploma}
      onDeleteCertificate={onDeleteCertificate}
      addCertificateCount={addCertificateCount}
      setAddCertificateCount={setAddCertificateCount}
      formik={formik}
      getFieldValue={getFieldValue}
      getFieldError={getFieldError}
      isFieldError={isFieldError}
      handleSubmit={handleSubmit}
      getErrorMessage={getErrorMessage}
      setFieldValue={setFieldValue}
      setValue={setValue}
      addDiplomaCount={addDiplomaCount}
      isAddDiplomaDisabled={addDiplomaCount - diploma.length > 0}
      isAddCertificateDisabled={addCertificateCount - certificate.length > 0}
    />
  );
};
