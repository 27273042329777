import React from 'react';

import styled, { css } from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { TimePickerRangeElem } from '../../common/time-picker-range';
import { TextElem } from '../../common/text';
import calendarWeekend from '../../asset/svg/button/calendar-weekend.svg';
import calendarWeekendActive from '../../asset/svg/button/calendar-weekend-active.svg';

import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { timeOption } from '../../data/time/constant';
import { SelectElem } from '../../common/select';
import { SCHEDULE_PAYLOAD_INTER } from './constant';
import { FieldTextElem } from '../../common/field-text';

export const Component: React.FC<{
  day: string;
  time?: [string, string];
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleSetWeekend: () => void;
  isWeekend?: boolean;
  setValue: Function;
  updatePayload: Function;
  payload?: SCHEDULE_PAYLOAD_INTER;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  day,
  time,
  handleSetWeekend,
  isWeekend,
  updatePayload,
  payload,
}) => {
  const isTimeValid = Array.isArray(time) && time[0] && time[1];
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SETTINGS.CONSULTATION.SUCCESS" />
      )}
      <Container>
        <DayContainer>
          <TextElem size="semiSmall" color="textPrimary" tid={day} />
        </DayContainer>
        <SelectWrapper>
          <WeekendButtonMobile
            disabled={isLoading}
            isWeekend={isWeekend}
            onClick={handleSetWeekend}
          >
            <ImgStyled
              src={isWeekend ? calendarWeekendActive : calendarWeekend}
              alt="calendar"
            />
          </WeekendButtonMobile>

          {!isWeekend && (
            <>
              <SelectElem
                name="startTime"
                minHeight="48px"
                isDisabled={isLoading || isWeekend}
                dynamic
                onChange={(
                  _name: string,
                  value: { value: string; label: string },
                ) => {
                  updatePayload('startTime', value?.value);
                }}
                options={timeOption}
                value={{ value: payload?.startTime, label: payload?.startTime }}
              />
              <SelectElem
                name="endTime"
                minHeight="48px"
                isDisabled={isLoading || isWeekend}
                dynamic
                onChange={(
                  _name: string,
                  value: { value: string; label: string },
                ) => {
                  updatePayload('endTime', value?.value);
                }}
                options={timeOption.filter((option) => {
                  const min = payload?.startTime || '00:00';

                  return option.value > min;
                })}
                value={{ value: payload?.endTime, label: payload?.endTime }}
              />
            </>
          )}
          {isWeekend && (
            <FakeInput>
              <TextElem
                tid="SCHEDULE.WEEKEND_TITLE"
                type="light"
                color="textSecondary"
                size="semiSmall"
              />
            </FakeInput>
          )}
        </SelectWrapper>
        <WeekendButtonDesktop
          disabled={isLoading}
          isWeekend={isWeekend}
          onClick={handleSetWeekend}
        >
          <ImgStyled
            src={isWeekend ? calendarWeekendActive : calendarWeekend}
            alt="calendar"
          />
        </WeekendButtonDesktop>
      </Container>
    </>
  );
};

const FakeInput = styled.div`
  padding: 16px 20px;
  position: relative;
  height: 48px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.INPUT]}px;
  width: 100%;

  overflow: hidden;

  display: flex;
  align-items: center;

  @media screen and (max-width: 1160px) {
    span {
      font-size: 13px;
    }
  }

  && > span {
    width: calc(100% - 40px);
    display: block;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    font-size: ${({ theme }) => theme[SIZE_FONT_ENUM.SEMI_SMALL]}px;

    @media screen and (max-width: 1160px) {
      font-size: 13px;
    }
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  width: 100%;
`;

const ImgStyled = styled.img`
  width: ${Spacing(5)};
  height: ${Spacing(5)};
`;

const WeekendButton = styled.button<{ isWeekend?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT_OPACITY]};
  padding: ${Spacing(1)} 14px;
  max-width: ${Spacing(12)};
  min-width: ${Spacing(12)};
  min-height: ${Spacing(12)};

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  ${({ isWeekend }) => {
    if (isWeekend) {
      return css`
        background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
      `;
    }
  }}
`;

const WeekendButtonDesktop = styled(WeekendButton)`
  display: flex;

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const WeekendButtonMobile = styled(WeekendButton)`
  display: none;

  @media screen and (max-width: 1160px) {
    display: flex;
  }
`;

const DayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing(1)} ${Spacing(5)};
  width: 100%;
  max-width: 132px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  @media (max-width: 1160px) {
    max-width: unset;
    padding: 15px ${Spacing(5)};

    span {
      font-size: 13px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  gap: ${Spacing(3)};

  @media (max-width: 1160px) {
    flex-direction: column;
  }
`;
