import { convertDay } from '../../lib/lang/service';
import {
  PAYMENT_ITEM_DATA_RAW_INTER,
  PAYMENT_ITEM_DATA_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
  PAYMENT_VALUTE_SYMBOL,
} from './constant';

export const convertPayment = (
  payment: PAYMENT_ITEM_DATA_RAW_INTER,
): PAYMENT_ITEM_DATA_INTER => {
  return {
    ...payment,
    createDate: convertDay(payment.createDate),
    valute: PAYMENT_VALUTE_SYMBOL[payment.valute],
  };
};

export const convertPaymentList = (
  paymentList: PAYMENT_ITEM_DATA_RAW_INTER[],
): PAYMENT_ITEM_LIST_DATA_INTER => {
  return {
    list: paymentList?.map((payment: PAYMENT_ITEM_DATA_RAW_INTER) => {
      return convertPayment(payment);
    }),
    isEmpty: !paymentList?.length,
  };
};
