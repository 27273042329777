import React from 'react';

import { FormikValues } from 'formik';
import styled from 'styled-components';

import { FORM_VALUE_ENUM } from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { AlertActionElem } from '../../common/alert-action';
import { FieldPasswordElem } from '../../common/field-password';
import { FormElem } from '../../common/form';
import { LoaderElem } from '../../common/loader';
import { LinkElem } from '../../common/link';
import { AUTH_RECOVERY_PAGE_PATH } from '../../page/auth-recovery';
import { AUTH_TYPE } from '../../data/auth/constant';
import { AUTH_SIGNUP_PAGE_PATH } from '../../page/auth-signup';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TabElem } from '../../common/tab';
import { AUTH_LOGIN_PAGE_PATH } from '../../page/auth-login';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  method: AUTH_TYPE;
  setMethod: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  method,
  setMethod,
}) => {
  return (
    <Form onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <TitleContainer>
        <TextContainer>
          <TextStyled
            oneLine
            size="semiSmall"
            color="textPrimary"
            type="medium"
            tid="AUTH.LOGIN.DESCRIPTION.PREFIX"
          />
          {'  '}
          <TextStyled
            size="semiSmall"
            color="textSecondary"
            type="regular"
            tid="AUTH.LOGIN.DESCRIPTION.SUFIX"
          />
        </TextContainer>
        {'  '}
        <TextStyled
          size="semiSmall"
          color="textSecondary"
          type="regular"
          tid="AUTH.LOGIN.DESCRIPTION.POSTFIX"
        />
      </TitleContainer>

      <GridStyled size="mod">
        <TabElem
          tabList={[
            { tid: 'AUTH.LOGIN.TITLE', path: AUTH_LOGIN_PAGE_PATH },
            { tid: 'AUTH.SIGNUP.TITLE', path: AUTH_SIGNUP_PAGE_PATH },
          ]}
        />

        <GridElem spacing={4}>
          <FieldTextElem
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="AUTH.LOGIN.EMAIL_PLACEHOLDER"
            title="AUTH.LOGIN.EMAIL"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
            type="email"
          />

          <FieldPasswordElem
            name={FORM_VALUE_ENUM.PASSWORD}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="AUTH.LOGIN.PASSWORD_PLACEHOLDER"
            title="AUTH.LOGIN.PASSWORD"
            value={getFieldValue(FORM_VALUE_ENUM.PASSWORD)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PASSWORD)}
            error={isFieldError(FORM_VALUE_ENUM.PASSWORD)}
          />
        </GridElem>
        <GridElem spacing={4}>
          <RecoveryContainer>
            <RecoveryTextStyled
              size="semiSmall"
              type="regular"
              color="textSecondary"
              tid="AUTH.LOGIN.LINKS.RECOVERY.LINK_DESCRIPTION"
            />
            <LinkElem
              size="semiSmall"
              type="medium"
              justifySelf="center"
              color="textPrimary"
              tid="AUTH.LOGIN.LINKS.RECOVERY.LINK"
              link={AUTH_RECOVERY_PAGE_PATH}
            />
          </RecoveryContainer>
          <ButtonElem
            disabled={isSubmitDisabled()}
            color="buttonPrimary"
            type="submit"
            fill="solid"
            tid="AUTH.LOGIN.BUTTON"
            sizeText="main"
          />
        </GridElem>

        {isError && <AlertActionElem text={errorMessage} type="error" />}
      </GridStyled>
    </Form>
  );
};

const RecoveryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 2px;
`;

const RecoveryTextStyled = styled(TextElem)`
  line-height: 1.5em;
`;

const TextStyled = styled(TextElem)`
  line-height: 1.7em;
`;

const GridStyled = styled(GridElem)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  padding: ${Spacing(7)};
  gap: ${Spacing(6)};
  box-shadow: 0px 0px 50px 0px #24231e0d;
  max-width: 366px;
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const Form = styled(FormElem)`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(10)};
  max-width: 628px;
  align-items: center;
`;
