import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { PsychoFilterContainer } from '../../epic/psycho-filter';
import { PsychoListContainer } from '../../epic/psycho-list';
import ReactDOM from 'react-dom';
import { MobileMenuContainer } from '../../epic/mobile-menu';

export const Page: React.FC<RouteComponentProps> = () => {
  const [filterOption, setFilterOption] = useState<{
    psychoCategory: string;
    methodList: string;
    requestList: string;
  }>();


  const setOption = (e: any) => {
    setFilterOption(e);
  };

  return (
    <>
      {ReactDOM.createPortal(
        <PsychoFilterContainer setOption={setOption} />,
        document.getElementById('layoutApp') || document.body,
      )}
      <PageElem>
        <LayoutAppElem>
          <LayoutElem size="default">
            <LayoutSectionElem spacing={5}>
              <PsychoListContainer filterOption={filterOption} />
            </LayoutSectionElem>
          </LayoutElem>
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};
