import React from 'react';

import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import icon from '../../asset/svg/menu-app/logout.svg';
import styled from 'styled-components';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { ButtonElem } from '../../common/button';

export const Component: React.FC<{
  onSubmitForm: any;
}> = ({ onSubmitForm }) => {
  return (
    <Container spacing={3}>
      <FlexContainer>
        <TextElem
          size="main"
          color="textPrimary"
          type="medium"
          tid="SETTINGS.LOGOUT.TITLE"
        />
        <TextElem
          size="small"
          color="textSecondary"
          type="light"
          tid="SETTINGS.LOGOUT.DESCRIPTION"
        />
      </FlexContainer>
      <LogoContainer>
        <ButtonElem
          onClick={onSubmitForm}
          type="cancel"
          tid="SETTINGS.LOGOUT.BUTTON"
        />
        {/* <img src={icon} />
        <TextElem tid="SETTINGS.LOGOUT.BUTTON" color="error" /> */}
      </LogoContainer>
    </Container>
  );
};

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(2)};

  cursor: pointer;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(6)};
  align-items: start;
`;

const Container = styled(GridElem)`
  width: 100%;
  padding: ${Spacing(7)};
  display: flex;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
  align-items: center;
  position: relative;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  justify-content: space-between;
`;
