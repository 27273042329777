import { HttpRequest } from '../../lib/http';
import { API, WEEKEND_PAYLOAD_INTER } from './constant';

export const updateAction = (payload: WEEKEND_PAYLOAD_INTER) => {
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL,
    data: { ...payload },
  });
};
