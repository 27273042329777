import { HttpRequest } from '../../lib/http';
import { DAY_ENUM } from '../schedule-list/constant';
import { API } from './constant';

export const updateAction = (payload: {
  day: DAY_ENUM;
  startTime?: string;
  endTime?: string;
  isWeekend?: boolean;
}) => {
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL,
    data: payload,
  });
};
