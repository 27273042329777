import React from 'react';
import { PageElem } from '../../common/page/index';
import { LayoutAppElem } from '../../common/layout-app/index';
import { LayoutElem } from '../../common/layout/index';
import { LayoutSectionElem } from '../../common/layout-section/index';
import { HeaderContainer } from '../../epic/header/index';
import { LayoutFooterElem } from '../../common/layout-footer/index';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app/index';
import { PaymentListContainer } from '../../epic/payment-list';
import paymentIcon from '../../asset/svg/menu-app/payment.svg';
import { MobileMenuContainer } from '../../epic/mobile-menu';

export const Page: React.FC = () => {
  return (
    <>
      <PageElem>
        <LayoutAppElem>
          <HeaderContainer
            icon={paymentIcon}
            title="PAYMENT.HEADER"
            size="default"
          />

          <LayoutElem size="default">
            <LayoutSectionElem>
              <PaymentListContainer />
            </LayoutSectionElem>
          </LayoutElem>
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};
