import React, { ReactNode } from 'react';
import { IonContent } from '@ionic/react';
import { useSelector } from 'react-redux';

import styled, { css } from 'styled-components';

import {
  COLOR_ENUM,
  COLOR_TYPE,
  DARK_COLOR_DATA,
  LIGHT_COLOR_DATA,
} from '../../theme/color';
import { THEME_MODULE_NAME } from '../../data/theme';
import { THEME_ENUM } from '../../data/theme/constant';
import { Spacing } from '../../theme';
import backgroundImage from '../../asset/background.jpg';

export const Elem: React.FC<{
  children: ReactNode;
  background?: COLOR_TYPE;
  className?: string;
  oneColumn?: boolean;
  tab?: boolean;
}> = ({
  children,
  background = 'backgroundPrimary',
  className,
  oneColumn = false,
  tab = false,
}) => {
  const { state } = useSelector((s: any) => ({
    state: s[THEME_MODULE_NAME],
  }));

  // useEffect(() => {
  //   if (Capacitor.isNativePlatform()) {
  //     try {
  //       StatusBar.setBackgroundColor({
  //         color: `transparent`,
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }

  //     document
  //       .querySelector('meta[name="theme-color"]')
  //       ?.setAttribute('content', `transparent`);
  //   }
  // }, [background, state.type]);
  const bg =
    state.type === THEME_ENUM.LIGHT
      ? LIGHT_COLOR_DATA[COLOR_ENUM.WHITE]
      : DARK_COLOR_DATA[COLOR_ENUM.WHITE];
  return (
    <Background
      background={bg}
      className={className}
      oneColumn={oneColumn}
      tab={tab}
      id="layoutApp1"
    >
      <div id="layoutApp" />
      {children}
    </Background>
  );
};

const Background = styled(IonContent)<{
  background?: string;
  oneColumn?: boolean;
  tab: boolean;
}>`
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImage}) !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ::part(scroll) {
    background-image: url(${backgroundImage}) !important;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  ${({ background }) => {
    return css`
      /* --background: ${background}; */
    `;
  }}

  ${({ oneColumn, tab }) => {
    if (!oneColumn) {
      return css`
        ::part(scroll) {
          display: grid;
          background-color: ${({ theme }) =>
            theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

          grid-template-columns: 270px auto;
          grid-template-rows: ${tab ? '162px' : '92px'} auto;
          padding-bottom: ${Spacing(10)};

          background-image: url(${backgroundImage}) !important;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @media screen and (max-width: 1110px) {
            grid-template-columns: 220px auto;
          }
        }

        @media screen and (max-width: 1160px) {
          ::part(scroll) {
            grid-template-columns: unset;
            grid-template-rows: unset;
          }
        }
      `;
    }
  }};
`;
