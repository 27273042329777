import { CATEGORY_VIEW, GENDER_VIEW } from '../../epic/profile-psycho/constant';
import {
  USER_ITEM_DATA_RAW_INTER,
  USER_ITEM_DATA_INTER,
  USER_ITEM_LIST_DATA_RAW_INTER,
  USER_ITEM_LIST_DATA_INTER,
  methodViewObj,
  requestViewObj,
  PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
  PSYCHOLOGIST_ITEM_DATA_INTER,
} from './constant';

const convertArray = (
  array: string[] | null | any,
  viewObj: Record<string, string>,
) =>
  array
    ? array.map((e: number) => ({
        label: viewObj[Number(e)],
        value: Number(e),
      }))
    : null;

export const convertUser = (
  user: USER_ITEM_DATA_RAW_INTER,
): USER_ITEM_DATA_INTER => {
  return {
    ...user,
    methodList: convertArray(user?.methodList, methodViewObj),
    requestList: convertArray(user?.requestList, requestViewObj),
    gender: {
      label: user?.gender ? GENDER_VIEW[user.gender] : '',
      value: user?.gender,
    },
    needGender: user?.needGender ? user?.needGender : null,
    lgbtFrendly: String(user?.lgbtFrendly),
    psychoCategoryView: {
      label: user?.psychoCategory ? CATEGORY_VIEW[user?.psychoCategory] : '',
      value: user?.psychoCategory,
    },
  };
};

export const convertPsychologist = (
  user: PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
): PSYCHOLOGIST_ITEM_DATA_INTER => {
  return {
    ...user,
    methodList: convertArray(user?.methodList, methodViewObj),
    requestList: convertArray(user?.requestList, requestViewObj),
    gender: user?.gender ? user.gender : null,
    needGender: user?.needGender ? user?.needGender : null,
    lgbtFrendly: String(user?.lgbtFrendly),
  };
};

export const converPsychoFilter = (user: USER_ITEM_DATA_RAW_INTER) => {
  return {
    methodList: convertArray(user.methodList, methodViewObj),
    requestList: convertArray(user.requestList, requestViewObj),
    psychoCategory: user.psychoCategory,
  };
};

export const convertUserList = (
  userList: USER_ITEM_LIST_DATA_RAW_INTER,
): USER_ITEM_LIST_DATA_INTER => {
  return {
    list: userList.list?.map((user: USER_ITEM_DATA_RAW_INTER) => {
      return convertUser(user);
    }),
    isEmpty: !userList.list?.length,
  };
};
